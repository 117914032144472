body {
  background-color: $body-color;
  overflow-x: hidden;
}

::selection {
  background: lighten($color: $primary-color, $amount: 10);
  color: $white;
}

/* preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spin {
  height: 50px;
  width: 50px;
  border-width: 5px;
  border-style: solid;
  border-color:  rgba($color: $primary-color, $alpha: .75)  rgba($color: $primary-color, $alpha: .75)  rgba($color: $primary-color, $alpha: .25) rgba($color: $primary-color, $alpha: .25);
  border-radius: 100%;
  animation: clockwise 1s linear infinite;
}

@keyframes clockwise {
  to {
    transform: rotate(360deg) translatez(0);
  }
}
/* /preloader */

ol,
ul {
  list-style-type: none;
  margin: 0px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: .2s ease;

  &:focus {
    outline: 0;
  }
}

a:hover {
  color: $primary-color;
}

a.text-white,
a.text-light,
a.text-dark {
  &:hover {
    color: $primary-color !important;
  }
}

/* section */
.section {
  padding-top: 150px;
  padding-bottom: 150px;

  &-sm {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &-title {
    margin-bottom: 40px;

    &-border {
      position: relative;
      height: 5px;
      width: 30px;
      background: $primary-color;
      border-radius: 10px;
      margin-bottom: 20px;
      display: block;

      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 50%;
        background: $primary-color;
        border-radius: 10px;
        right: -60%;
        top: 0;
      }

      &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 25%;
        background: $primary-color;
        border-radius: 10px;
        right: -100%;
        top: 0;
      }

      &.border-center {
        margin-left: calc(50% - 27px);
      }

      &.border-white {
        background: $white;

        &::before {
          background: $white;
        }

        &::after {
          background: $white;
        }
      }

      &.border-inline {
        display: inline-block;
        margin-bottom: 5px;
      }
    }
  }
}

.subtitle{
  font-family: $primary-font;
  font-weight: 600;
  color: $text-color;
  @extend .h4;
}

.section-bottom-lg {
  padding-bottom: 240px;
}
/* /section */

/* background image */
.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}
/* /background image */

/* overlay */
.overlay {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $black;
    opacity: .6;
  }
}

.overlay-secondary {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $secondary-color;
    opacity: .85;
  }
}
/* /overlay */

/* common class */
.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: $primary-color !important;
}

.bg-secondary {
  background: $secondary-color !important;
}

.bg-secondary-darken {
  background: darken($color: $secondary-color, $amount: 3) !important;
}

.bg-gray {
  background: $gray !important;
}

.bg-gray-white {
  background-image: linear-gradient(to right, $gray 45%, $white 0%);
}

.bg-white-gray {
  background-image: linear-gradient(to right, $white 45%, $gray 0%);
}

.bg-white2-gray {
  background-image: linear-gradient(to right, $white 70%, $gray 0%);
}

.text-primary {
  color: $primary-color !important;
}

.text-color {
  color: $text-color;
}

.text-light {
  color: #ddd !important;
}

.text-dark {
  color: $text-color-dark;
}

.hilighted {
  background: $secondary-color;
  padding: 0 5px;
  border-radius: 2px;
  color: $white;
}

.letter-spacing {
  letter-spacing: 2px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.zindex-0 {
  z-index: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.min-height-400 {
  min-height: 400px;
}

.border-color {
  border-color: $border-color !important;
}

.border-muted {
  border-color: #404751 !important;
}

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.flex-basis-33 {
  flex-basis: 33.333333%;
}

.icon {
  font-size: 40px;
}

.icon-sm {
  font-size: 34px;
}

.icon-xs {
  font-size: 22px;
}

.icon-lg {
  font-size: 56px;
}

.box-shadow {
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
}

.translate-top{
  transform:translateY(-100px);
}

.transition{
  transition: .2s ease;
}

.font-size-30 {
  font-size: 30px;
}

.font-weight-semebold {
  font-weight: 600 !important;
}

.font-secondary {
  font-family: $secondary-font !important;
}

.text-decoration-none {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.border-md-right {
  border-right: 1px solid;

  @include tablet {
    border: 0;
  }
}
/* /common class */

/* icon animation */
.icon-bg {
  position: relative;
  background-color: rgba($color: $primary-color, $alpha: .2);
  display: inline-block;
  height: 100px;
  width: 100px;
  border-radius: 120px 135px 110px 90px;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 5px;
    background: rgba($color: $primary-color, $alpha: .05);
    border: 2px solid $primary-color;
    border-radius: 130px 120px 160px 130px;
  }

  .icon {
    line-height: 100px;
  }
}

.water-wave {
  position: relative;
  animation-name: water-wave;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &::before {
    animation-name: water-wave;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes water-wave {
  0% {
    border-radius: 120px 135px 110px 90px;

    &::before {
      border-radius: 130px 120px 160px 130px;
    }
  }

  25% {
    border-radius: 130px 140px 100px 110px;

    &::before {
      border-radius: 100px 147px 140px 120px;
    }
  }

  50% {
    border-radius: 110px 97px 150px 100px;

    &::before {
      border-radius: 102px 147px 140px 120px;
    }
  }

  75% {
    border-radius: 80px 107px 120px 90px;

    &::before {
      border-radius: 102px 147px 140px 120px;
    }
  }

  100% {
    border-radius: 120px 135px 110px 90px;

    &::before {
      border-radius: 130px 120px 160px 130px;
    }
  }
}
/* /icon animation */

/* breadcrumb */
.breadcrumb-item+.breadcrumb-item::before {
  color: $white;
}

/* google map */
#map_canvas {
  height: 500px;
}

/* form control */
.form-control {
  height: 55px;
  padding: 0 30px;

  &-sm {
    height: 45px;
  }

  &:focus {
    box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
    border-color: $primary-color !important;
  }
}

.focus-shadow-none {
  box-shadow: none !important;
}

textarea.form-control {
  height: 150px;
}

textarea.form-control-sm {
  height: 100px;
}
/* /form control */

/* social icon */
.social-icon {
  li {
    a {
      display: inline-block;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      color: $white;
      border: 1px solid $white;
      background: transparent;
      line-height: 45px;
      text-align: center;

      &:hover {
        color: $primary-color;
        background: $white;
      }
    }
  }
}

.social-icon-alt {
  li {
    a {
      display: inline-block;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      color: $white;
      border: 1px solid $white;
      background: transparent;
      line-height: 45px;
      text-align: center;

      &:hover {
        color: $white;
        background: $primary-color;
        border-color: $primary-color;
      }
    }
  }
}
/* /social icon */

/* slick style */
.slick-slide {
  outline: 0;
}

/* slick arrows */
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: rgba($color: $primary-color, $alpha: .5);
  color: $white;
  border: 0;
  line-height: 70px;
  font-size: 35px;

  &::before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    background: transparent;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    transition: .2s ease;
  }

  &::after {
    position: absolute;
    content: '';
    height: 2px;
    width: 25px;
    top: 50%;
    transform: translateY(-50%);
    background: $white;
    transition: .2s ease;
  }

  @include mobile {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    background: $primary-color;
  }
}

.prevArrow {
  left: 0px;

  &::before {
    border-left: 2px solid $white;
    border-top: 2px solid $white;
    right: 35px;
  }

  &::after {
    right: 20px;
  }
}

.nextArrow {
  right: 0px;

  &::before {
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
    left: 35px;
  }

  &::after {
    left: 20px;
  }
}
/* /slick arrows */

/* slick dots */
.slick-dots {
  padding-left: 0;
  text-align: center;
  position: absolute;
  bottom: -185px;
  left: 0;
  right: 0;
  z-index: 9;

  li {
    display: inline-block;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 20px;
    transition: .2s ease;
    box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);

    img {
      height: 70px;
      width: 70px;
      border-radius: 50%;
    }

    &.slick-active {
      transform: scale(1.2);
    }
  }
}
/* /slick dots */